import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { getIdToken } from 'utils/cognitoHelper';
import { useNavigate } from 'react-router-dom';
import base64url from 'base64url';
import KeyIcon from '@mui/icons-material/Key';
import { Avatar, Box, Container, Typography } from '@mui/material';
import { useSignOut } from 'hooks';



export const KeyRegister = () => {
  const signOut = useSignOut();
  const [registrationMessage, setRegistrationMessage] = useState<string>('');
  const [idToken, setIdToken] = useState<string>('');

  const theme = useTheme();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const idToken = await getIdToken();
      if (!idToken) {
        navigate('/login');
        return;
      }
      setIdToken(idToken);
    })();
  }, [navigate]);


  const handleLogout = async () => {
      await signOut(); // ログイン前にログアウト
      navigate('/login');
  };


  const base64UrlToBase64 = (base64Url: any): any => {
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    switch (base64.length % 4) {
      case 2:
        base64 += '==';
        break;
      case 3:
        base64 += '=';
        break;
      default:
        break;
    }

    return base64;
  }

  const publicKeyCredentialToJSON = (pubKeyCred: any): any => {
    if(pubKeyCred instanceof Array) {
      let arr = [];
      for(let i of pubKeyCred) {
        arr.push(publicKeyCredentialToJSON(i));
      }
      return arr
    }

    if (pubKeyCred instanceof ArrayBuffer) {
      const buffer = Buffer.from(pubKeyCred);
      return base64url.encode(buffer);
    }

    if(pubKeyCred instanceof Object) {
      const obj: Record<string, any> = {};

      for (let key in pubKeyCred) {
        obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
      }

      return obj
    }

    return pubKeyCred;
  };

  const handleKeyRegister = async () => {
    setRegistrationMessage('');
    const headers = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const baseUrl = process.env.REACT_APP_AUTH_BASEURL;

    try {
      const response = await axios.post<PublicKeyCredentialCreationOptions>(
        baseUrl + '/fido2/attestation/options',
        {},
        headers
      );
      const options:PublicKeyCredentialCreationOptions = response.data;
      options.challenge = Uint8Array.from(atob(base64UrlToBase64(options.challenge)), (c) => c.charCodeAt(0));
      if (typeof options.user.id === 'string') {
        options.user.id = new TextEncoder().encode(options.user.id);
      } else if (options.user.id instanceof ArrayBuffer) {
        options.user.id = new Uint8Array(options.user.id);
      } else if (ArrayBuffer.isView(options.user.id)) {
        options.user.id = new Uint8Array(options.user.id.buffer);
      }
      const credential = await navigator.credentials.create({ publicKey: options }) as PublicKeyCredential;

      const res = publicKeyCredentialToJSON(credential.response);
      if (credential) {
        const attestationResponse = {
          id: credential.id,
          rawId: Array.from(new Uint8Array(credential.rawId)),
          type: credential.type,
          response: res
        };

        await axios.post(baseUrl + '/fido2/attestation/result',
          {
            ...attestationResponse,
          },
          headers
        );

        setRegistrationMessage('登録が完了しました。続けて登録する場合は別のYubiKeyをセットし、登録開始ボタンを押してください');
      } else {
        setRegistrationMessage('鍵の読み取りに失敗しました');
      }
    } catch (error) {
      console.error('Error registering security key:', error);
      setRegistrationMessage('登録に失敗しました');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        css={css`
          padding-top: 10rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem; /* Adjust padding as needed */
        `}
      >
        <Avatar
        css={css`
          margin: 0.5rem;
          background-color: #f48fb1; 
        `}
      >
          <KeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          YubiKey登録
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={handleKeyRegister}
          color="primary"
          css={css`
            margin: ${theme.spacing(3, 0, 2)};
          `}
        >
          登録開始
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={handleLogout}
          color="warning"
          css={css`
            margin: ${theme.spacing(3, 0, 2)};
          `}
        >
          ログアウト
        </Button>
        {registrationMessage && (
          <Typography
            css={css`
              margin-top: 2rem;
            `}
          >
            {registrationMessage}
          </Typography>
        )}
      </Box>
    </Container>
  );
};
