import { getFmsAuthenticatedUser } from './localStorageHelper';
import { cognitoAuth } from './cognitoHelper';
import { fmsAuth } from './externalApiHelper';
import { isUsablePastAuth } from './authJudgeHelper';
import axios from 'axios';
import base64url from 'base64url';

export type CurrentUserType =
  | {
      username: string;
      hospitalName: string;
      hpUID: string;
      lat: number | null;
      lng: number | null;
      qr_compression_flag: boolean;
      dashboard_button_flag: boolean;
    }
  | undefined;

export const getAuthenticatedUser = async (): Promise<CurrentUserType> => {
  const fmsUser = getFmsAuthenticatedUser();
  if (!fmsUser.isFmsAuthenticated) {
    return undefined;
  }
  return fmsUser.data;
};

type AuthenticateResponse =
  | {
      isAuthenticated: true;
      data: {
        username: string;
        hospitalName: string;
        hpUID: string;
        hostUrl: string;
        lat: number;
        lng: number;
        cognito_username: string;
        cognito_password: string;
        qr_compression_flag: boolean;
        dashboard_button_flag: boolean;
      };
    }
  | { isAuthenticated: boolean; data: undefined };

export const authenticate = async (
  user_name: string,
  user_pass: string
): Promise<AuthenticateResponse> => {
  if (isUsablePastAuth(user_name)) {
    // 従来の認証がまだ使用できる病院
    const response = await _authenticatePast(user_name, user_pass);
    if (response.isAuthenticated) {
      // 従来の認証で認証が成功したので、そのままレスポンス
      return response;
    }
  }
  let isCustomAuthSuccess = false;
  const baseUrl = process.env.REACT_APP_AUTH_BASEURL;
  // const baseUrl = 'https://txq81qxgtg.execute-api.ap-northeast-1.amazonaws.com';
  try {
    const response = await axios.post(baseUrl + '/fido2/auth/login', {
      username: user_name,
      password: user_pass,
    });
    const data = response.data;
    // キー登録なし(単純なCognito認証)
    if (data.AuthenticationResult) {
      isCustomAuthSuccess = true;
    } else {
      // キー登録あり(カスタム認証)
      const sessionId = data.Session;
      const username = data.ChallengeParameters.USERNAME;
      const options = JSON.parse(data.ChallengeParameters.options);
      options.challenge = base64url.toBuffer(options.challenge).buffer;

      options.allowCredentials = options.allowCredentials.map((c: any) => {
        c.id = base64url.toBuffer(c.id).buffer;
        return c;
      });

      const assertion = await navigator.credentials.get({ publicKey: options }) as PublicKeyCredential;
      if (assertion) {
        const result = publicKeyCredentialToJSON(assertion);
        const response = await axios.post(baseUrl + '/fido2/auth/mfa', {
          sessionId: sessionId,
          username,
          assertion: result
        });
        if (response.data.AuthenticationResult) {
          isCustomAuthSuccess = true;
        }
      } else {
        console.error('Error: Assertion failed or was cancelled');
      }
    }
  } catch (error: any) {
    console.log(error);
  }

  if (isCustomAuthSuccess) {
    const { isCognitoAuthenticated } = await cognitoAuth(user_name, user_pass);
    return { isAuthenticated: isCognitoAuthenticated, data: undefined };
  } else {
    return { isAuthenticated: false, data: undefined };
  }
};

const _authenticatePast = async (
  user_name: string,
  user_pass: string
): Promise<AuthenticateResponse> => {
  const { isError, data } = await fmsAuth(user_name, user_pass);
  if (!isError && data) {
    const isCognitoAuthenticated = await cognitoAuth(
      data.cognito_username,
      data.cognito_password
    );
    if (isCognitoAuthenticated) {
      return {
        isAuthenticated: true,
        data: { ...data, hostUrl: 'https://fms1.txpmedical.com' },
      };
    } else {
      return { isAuthenticated: false, data: undefined };
    }
  } else {
    return { isAuthenticated: false, data: undefined };
  }
};


const publicKeyCredentialToJSON = (pubKeyCred: any): any => {
  if(pubKeyCred instanceof Array) {
    let arr = [];
    for(let i of pubKeyCred) {
      arr.push(publicKeyCredentialToJSON(i));
    }
    return arr
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    const buffer = Buffer.from(pubKeyCred);
    return base64url.encode(buffer);
  }

  if(pubKeyCred instanceof Object) {
    const obj: Record<string, any> = {};

    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj
  }

  return pubKeyCred;
};
